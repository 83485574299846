import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSliderChange } from '@angular/material/slider';

@Component({
  selector: 'app-fetch-data',
  templateUrl: './fetch-data.component.html'
})
export class FetchDataComponent {
  public forecasts: WeatherForecast[];
  public indexResult: number;
  public currentSummary: string;
  public curreantWeather: WeatherForecast;


  //constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
  //  http.get<WeatherForecast[]>(baseUrl + 'weatherforecast').subscribe(result => {
  //    this.forecasts = result;
  //  }, error => console.error(error));
  //}

  constructor(private httpClient: HttpClient) { }
  ngOnInit() {
    this.httpClient.get<WeatherForecast[]>("assets/weather.json").subscribe(data => {
      console.log(data);
      this.forecasts = data;
    })
  }

  public displaySingle() {
    //this.currentCount++;
    this.indexResult = 2;
    this.currentSummary = this.forecasts[this.indexResult].summary;
  }

  onInputChange(event: MatSliderChange) {
    console.log("This is emitted as the thumb slides");
    console.log(event.value);
    this.currentSummary = this.forecasts[event.value].summary;
    this.curreantWeather = this.forecasts[event.value];
  }
}

interface WeatherForecast {
  date: string;
  temperatureC: number;
  temperatureF: number;
  summary: string;
}
