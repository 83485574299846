import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSliderChange } from '@angular/material/slider';
import {
  Feature, FeatureCollection, GeometryCollection, LineString,
  MultiLineString, MultiPoint, MultiPolygon, Point, Polygon, GeoJsonGeometryTypes,
  GeoJsonTypes, GeometryObject
} from "geojson";

@Component({
  selector: 'app-fetch-geojson',
  templateUrl: './fetch-geojson.component.html',
  styleUrls: ['./fetch-geojson.component.css']
})
export class FetchGeojsonComponent implements OnInit {
  public forecasts: WeatherForecast[];
  public indexResult: number;
  public currentSummary: string;
  public curreantWeather: WeatherForecast;
  public featureCollection: FeatureCollection;
  public currentFeature: Feature;


  constructor(private httpClient: HttpClient) { }
  ngOnInit() {
    this.httpClient.get<FeatureCollection>("assets/geojsonsample.json").subscribe(data => {
      console.log(data);
      this.featureCollection = data;
    })
  }

  public displaySingle() {
    //this.currentCount++;
    this.indexResult = 2;
    this.currentSummary = this.forecasts[this.indexResult].summary;
  }

  onInputChange(event: MatSliderChange) {
    console.log("This is emitted as the thumb slides");
    console.log(event.value);
    this.currentFeature = this.featureCollection.features[event.value];
  }
}

interface WeatherForecast {
  date: string;
  temperatureC: number;
  temperatureF: number;
  summary: string;
}

//interface FeatureCollection {
//  features: Feature[];
//}

//interface Feature {
//  type: string;
//  properties: Property;
//  geometry: Geometry;
//}

//interface Geometry {
//  type: string;
//  coordinates: Position[];
//}

//interface Position {

//}

//interface Property {
//  prop0: string;
//  prop1: string;
//}
