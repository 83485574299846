import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FeatureCollection } from 'geojson';
import { MatSliderChange } from '@angular/material/slider';

@Component({
  selector: 'app-pipelinenetworks',
  templateUrl: './pipelinenetworks.component.html',
  styleUrls: ['./pipelinenetworks.component.css']
})
export class PipelinenetworksComponent implements OnInit {
  public results: Results;
  public currentNetwork: PipelineNetwork;
  public pointsString: string;

  //constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
  //  http.get<PipelineNetwork[]>(baseUrl + 'api/pipelineroutesegments/network').subscribe(result => {
  //    this.pipelineNetwork = result;
  //  }, error => console.error(error));
  //}

  //ngOnInit() {
  //}

  constructor(private httpClient: HttpClient) { }
  ngOnInit() {
    this.httpClient.get<Results>("assets/resultsDemo.json").subscribe(data => {
      console.log(data);
      this.results = data;
      this.currentNetwork = this.results.pipelineNetworks[0];
      //this.pointsString = "0, 0 100, 100";
      //this.pointsString = this.results.map.featureCollection.features[0].geometry.coordinates;       
    })
  }

  //constructor(private httpClient: HttpClient) { }
  //ngOnInit() {
  //  this.httpClient.get<PipelineNetwork>("assets/results.json").subscribe(data => {
  //    console.log(data);
  //    this.currentNetwork = data;
  //  })
  //}



  onInputChange(event: MatSliderChange) {
    console.log("This is emitted as the thumb slides");
    console.log(event.value);
    this.currentNetwork = this.results.pipelineNetworks[event.value-1];
  }

  linewidth(diameter: number): number {
    return diameter * 0.5;
  }
}





interface Results {
  pipelineNetworks: PipelineNetwork[];
  map: Map;
  terminalFeatureCollection: FeatureCollection;
  sourceFeatureCollection: FeatureCollection;
}

interface PipelineNetwork {
  //pipelines: Pipeline[];
  id: number;
  totalCost: number;
  arrivalPressure: number;
  featureCollection: FeatureCollection;
}


interface Map {
  featureCollection: FeatureCollection;
}

//interface Pipeline {
//  pipelineRouteSegments: PipelineRoutePoint[];
//}

//interface PipelineRoutePoint {
//  x1: number;
//  y1: number;
//  x2: number;
//  y2: number;
//  description: string;
//}


